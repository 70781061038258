var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"table",staticClass:"elevation-1",attrs:{"page":_vm.options.page,"pageCount":_vm.numberOfPages,"headers":_vm.headers,"items":_vm.items,"sort-by":null,"sort-desc":true,"options":_vm.options,"server-items-length":_vm.options.total,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 25, 50],
    },"items-per-page":_vm.options.itemsPerPage,"loading-text":"Chargement","no-data-text":"Pas de données","no-results-text":"Pas de résultats"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{staticClass:"ml-2 mb-1 shrink",attrs:{"append-icon":"mdi-magnify","label":"Recherche","single-line":"","hide-details":""},on:{"change":_vm.debouncedGetData},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{staticClass:"mx-0 mb-1 mt-6 shrink",attrs:{"dense":"","single-line":"","label":"Champ de recherche","items":_vm.searchFields},model:{value:(_vm.searchField),callback:function ($$v) {_vm.searchField=$$v},expression:"searchField"}})],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"4"}},[_c('date-range-filter',{attrs:{"periodType":"past-and-today","format":"date","range":30,"initialValue":_vm.dates,"showBtnList":['ALL','LAST_MONTH'],"outlined":true},on:{"valueChanged":_vm.setDates}})],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.topics.items,"loading":_vm.topics.loading,"search-input":_vm.topics.search,"item-text":"topic","item-value":"id","cache-items":"","hide-no-data":"","label":"Par topic","dense":"","clearable":""},on:{"update:searchInput":function($event){return _vm.$set(_vm.topics, "search", $event)},"update:search-input":function($event){return _vm.$set(_vm.topics, "search", $event)}},model:{value:(_vm.topics.select),callback:function ($$v) {_vm.$set(_vm.topics, "select", $$v)},expression:"topics.select"}})],1)],1)]},proxy:true},{key:"item.img_src",fn:function(ref){
    var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-img',{attrs:{"src":item.img_src,"max-height":"50px","max-width":"100px"}})],1)]}},{key:"item.title",fn:function(ref){
    var item = ref.item;
return [(item.title.length > _vm.tableTitleMaxLen)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('a',{on:{"click":function($event){return _vm.openDialog(item)}}},[_vm._v(" "+_vm._s(_vm.truncate(item.title, _vm.tableTitleMaxLen))+" ")])])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.title)+" ")])])],1):_c('span',[_c('a',{on:{"click":function($event){return _vm.openDialog(item)}}},[_vm._v(_vm._s(item.title))])])]}},{key:"item.url",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":item.url,"target":"_blank"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getHostFromUrl(item.url))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.url))])])]}},{key:"item.content",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.truncate(item.content, 30))+" ")]}}],null,true)}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"800"},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('v-card',{staticClass:"dialog-item"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogItem.img_src != ''),expression:"dialogItem.img_src != ''"}]},[_c('v-img',{attrs:{"height":"360","aspect-ratio":"16/9","src":_vm.dialogItem.img_src}}),_c('a',{staticClass:"dialog-image-a mr-1",attrs:{"href":_vm.dialogItem.img_src,"target":"_blank"}},[_c('v-icon',{},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])],1)],1),_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.dialogItem.title))])]),_c('v-card-text',{staticClass:"infos"},[_c('ul',[_c('li',[_vm._v(" Url: "),_c('a',{attrs:{"href":_vm.dialogItem.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.dialogItem.url)+" ")])]),_c('li',[_vm._v(" Date : "+_vm._s(_vm.dialogItem.date)+" ◊◊◊ Discover depuis: "+_vm._s(_vm.dialogItem.date_disco)+" ◊◊◊ Diff: "+_vm._s(_vm.dialogItem.diff_date)+" ")]),_c('li',[_vm._v("Content length : "+_vm._s(_vm.dialogItem.content_length))])]),_c('v-divider',{staticClass:"mx-4 my-3"}),_vm._v(" "+_vm._s(_vm.dialogItem.content)+" ")],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("Fermer")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }