<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-scale-transition"
    max-width="800"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn-toggle
        class="range-dates-picker-group"
        :class="showBtnList.length > 0 ? 'with-fast-btns' : ''"
      >
        <v-text-field
          v-model="dateRangeText"
          label="Date range"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          outlined
          dense
        ></v-text-field>
        <v-btn
          v-for="item in superFastBtnList"
          :key="item.id"
          @click="setDatesFast(item.id)"
          color="primary"
        >
          {{ item.name }}
        </v-btn>
      </v-btn-toggle>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <!-- <v-toolbar color="primary" dark>Sélectionner une pèriode</v-toolbar> -->
        <v-card-title class="ma-3 justify-center">
          Période sélectionnée: <b class="ml-3">{{ dateRangeText }}</b>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-date-picker
                v-model="datesTmp"
                range
                :type="format"
                v-bind="{
                  ...(dateMin ? { min: dateMin } : {}),
                  ...(dateMax ? { max: dateMax } : {}),
                }"
                locale="FR-fr"
                first-day-of-week="1"
                selected-items-text="Durée selectionnée"
                :disabled="disabled"
                :outlined="outlined"
              ></v-date-picker>
            </v-col>
            <v-col>
              <div v-for="item in fastSelectRanges.slice(0, 1)" :key="item.id">
                <v-btn @click="setFastSelectRanges(item.id)">{{
                  item.full_name
                }}</v-btn>
              </div>
              <br />
              <div>
                <span
                  v-for="item in fastSelectRanges.slice(1, 3)"
                  :key="item.id"
                >
                  <v-btn @click="setFastSelectRanges(item.id)">{{
                    item.full_name
                  }}</v-btn>
                </span>
              </div>
              <br />
              <div v-for="item in fastSelectRanges.slice(3, 6)" :key="item.id">
                <v-btn @click="setFastSelectRanges(item.id)">{{
                  item.full_name
                }}</v-btn>
              </div>
              <br />
              <div
                v-for="item in fastSelectRanges.slice(
                  6,
                  fastSelectRanges.length
                )"
                :key="item.id"
              >
                <v-btn @click="setFastSelectRanges(item.id)">{{
                  item.full_name
                }}</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="dialog.value = false">Annuler</v-btn>
          <v-btn color="primary" @click="confirmDates"> Appliquer </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
/*
// ***** USE: *****
// <template>
//   <div>
//     <date-range-filter
//       @valueChanged="setDates"
//       :range="30"
//       :showBtnList="['ALL', 'LAST_MONTH']"
//       :outlined="true"
//     ></date-range-filter>
//   </div>
// </template>
// <script>
//   import DateRangeFilter from "@/components/common/filters/DateRangeFilter.vue";
//   export default {
//     name: "MyViewName",
//     components: {
//         DateRangeFilter,
//     },
//     methods:{
//       setDates(dates){
//         console.log("setDates", dates)
//       }
//     }
//   }
*/
import { mdiCalendar } from "@mdi/js";
import isEqual from "lodash/isEqual";
import { getDateStr, getMonthDateStr, ONE_DAY_MICROTIME } from "@/utils/dates";

const TODAY = new Date();
const YESTERDAY = new Date(TODAY.getTime() - ONE_DAY_MICROTIME);
const TOMORROW = new Date(TODAY.getTime() + ONE_DAY_MICROTIME);

export default {
  name: "DateRangeFilter",
  props: {
    periodType: {
      type: String,
      default: "",
      validator(value) {
        return ["past-and-today", "past-only", "future-only", ""].includes(
          value
        );
      },
    },
    initialValue: {
      type: Array,
      validator(value) {
        console.log("DateRangeFilter initialValue", value, Array.isArray(value) && [0,2].includes(value.length))
        return Array.isArray(value) && [0,2].includes(value.length);
      },
    },
    showBtnList: {
      type: Array,
      default: [],
    },
    range: {
      type: Number,
      default: 14,
    },
    min: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
    format: {
      type: String,
      default: "date",
      validator(value) {
        return ["date", "month"].includes(value);
      },
    },
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return [
          "admin",
          "audience",
          "c2c",
          "marmiton",
          "premium",
          "regie",
          "gd",
        ].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getDates",
    },
    storeUpdater: {
      type: String,
      default: "updateDates",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    dates: [],
    datesTmp: [],
    icons: {
      mdiCalendar,
    },
    superFastBtnList: [],
    fastSelectRanges: [
      {
        id: "ALL",
        name: "Sans limite",
        full_name: "Pas de limite",
      },
      {
        id: "TODAY",
        name: "Aujourd'hui",
        full_name: "Aujourd'hui",
      },
      {
        id: "YESTERDAY",
        name: "Hier",
        full_name: "Hier",
      },
      {
        id: "LAST_WEEK",
        name: "7 jours",
        full_name: "Les 7 derniers jours",
      },
      {
        id: "LAST_2_WEEKS",
        name: "14 jours",
        full_name: "Les 14 derniers jours",
      },
      {
        id: "LAST_MONTH",
        name: "1 mois",
        full_name: "Les 30 derniers jours",
      },
      {
        id: "LAST_2_MONTHS",
        name: "2 mois",
        full_name: "Les 2 derniers mois",
      },
      {
        id: "LAST_3_MONTHS",
        name: "3 mois",
        full_name: "Les 3 derniers mois",
      },
      {
        id: "LAST_6_MONTHS",
        name: "6 mois",
        full_name: "Les 6 derniers mois",
      },
      {
        id: "LAST_12_MONTHS",
        name: "12 mois",
        full_name: "Les 12 derniers mois",
      },
    ],
  }),
  created() {
    console.log("DateRangeFilter created")
    this.initRangeDates();
    this.initSuperFastBtnList();
  },

  methods: {
    initSuperFastBtnList() {
      this.superFastBtnList = this.fastSelectRanges.filter((x) =>
        this.showBtnList.includes(x.id)
      );
    },
    setDatesFast(fastSelectRangesId) {
      this.setFastSelectRanges(fastSelectRangesId);
      this.confirmDates();
    },
    confirmDates() {
      this.dates = this.datesTmp;
      if (this.useStore === false) {
        if (this.datesTmp !== this.initialValue) {
          this.$emit("valueChanged", this.dates);
        }
      } else {
        this.$store.dispatch(`${this.store}/${this.storeUpdater}`, this.dates);
      }
      this.dialog = false;
    },
    setFastSelectRanges(fastSelectRangesId) {
      switch (fastSelectRangesId) {
        case "ALL":
          this.datesTmp = [];
          break;
        case "TODAY":
          this.datesTmp = [this.getDateStr(TODAY), this.getDateStr(TODAY)];
          break;
        case "YESTERDAY":
          const YESTERDAY = new Date(TODAY.getTime() - ONE_DAY_MICROTIME);
          this.datesTmp = [
            this.getDateStr(YESTERDAY),
            this.getDateStr(YESTERDAY),
          ];
          break;
        case "LAST_WEEK":
          const LAST_WEEK_START = new Date(
            TODAY.getTime() - 7 * ONE_DAY_MICROTIME
          );
          this.datesTmp = [
            this.getDateStr(LAST_WEEK_START),
            this.getDateStr(TODAY),
          ];
          break;
        case "LAST_2_WEEKS":
          const LAST_2_WEEKS_START = new Date(
            TODAY.getTime() - 14 * ONE_DAY_MICROTIME
          );
          this.datesTmp = [
            this.getDateStr(LAST_2_WEEKS_START),
            this.getDateStr(TODAY),
          ];
          break;
        case "LAST_MONTH":
          const LAST_MONTH = new Date();
          LAST_MONTH.setMonth(TODAY.getMonth() - 1);
          this.datesTmp = [this.getDateStr(LAST_MONTH), this.getDateStr(TODAY)];
          break;
        case "LAST_2_MONTHS":
          const LAST_2_MONTHS = new Date();
          LAST_2_MONTHS.setMonth(TODAY.getMonth() - 2);
          this.datesTmp = [
            this.getDateStr(LAST_2_MONTHS),
            this.getDateStr(TODAY),
          ];
          break;
        case "LAST_3_MONTHS":
          const LAST_3_MONTHS = new Date();
          LAST_3_MONTHS.setMonth(TODAY.getMonth() - 3);
          this.datesTmp = [
            this.getDateStr(LAST_3_MONTHS),
            this.getDateStr(TODAY),
          ];
          break;
        case "LAST_6_MONTHS":
          const LAST_6_MONTHS = new Date();
          LAST_6_MONTHS.setMonth(TODAY.getMonth() - 6);
          this.datesTmp = [
            this.getDateStr(LAST_6_MONTHS),
            this.getDateStr(TODAY),
          ];
          break;
        case "LAST_12_MONTHS":
          const LAST_12_MONTHS = new Date();
          LAST_12_MONTHS.setMonth(TODAY.getMonth() - 12);
          this.datesTmp = [
            this.getDateStr(LAST_12_MONTHS),
            this.getDateStr(TODAY),
          ];
          break;
        default:
          alert("Une erreur est survenue");
          break;
      }
    },
    initRangeDates() {
      console.log("DateRangeFilter initRangeDates")
      console.log("-- this.storedDates?.length", this.storedDates?.length)
      console.log("-- Object.values(this.storedDates).some((x) => x === undefined)", Object.values(this.storedDates).some((x) => x === undefined))
      
      if (
        this.storedDates?.length !== 2 ||
        Object.values(this.storedDates).some((x) => x === undefined)
      ) {
        console.log("-- AAAA")
        if (this.periodType === "past-and-today") {
          if (this.dateMax !== "") {
            const endDate = new Date(this.dateMax);
            const startDate = new Date(
              endDate.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          } else {
            const endDate = TODAY;
            const startDate = new Date(
              TODAY.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          }
        } else if (this.periodType === "past-only") {
          if (this.dateMax !== "") {
            const endDate = new Date(this.dateMax);
            const startDate = new Date(
              endDate.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          } else {
            const endDate = YESTERDAY;
            const startDate = new Date(
              YESTERDAY.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          }
        } else if (this.periodType === "future-only") {
          if (this.dateMin !== "") {
            const startDate = new Date(this.dateMin);
            const endDate = new Date(
              startDate.getTime() + this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          } else {
            const startDate = TOMORROW;
            const endDate = new Date(
              TOMORROW.getTime() + this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          }
        } else {
          if (this.dateMin !== "" && this.dateMax !== "") {
            const startDate = new Date(this.dateMin);
            const endDate = new Date(this.dateMax);
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          } else if (this.dateMin !== "") {
            const startDate = new Date(this.dateMin);
            const endDate = new Date(
              startDate.getTime() + this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          } else if (this.dateMax !== "") {
            const endDate = new Date(this.dateMax);
            const startDate = new Date(
              endDate.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          } else {
            const endDate = TODAY;
            const startDate = new Date(
              TODAY.getTime() - this.range * ONE_DAY_MICROTIME
            );
            this.datesTmp = [
              this.getDateStr(startDate),
              this.getDateStr(endDate),
            ];
          }
        }
      } else if (!isEqual(this.datesTmp, this.storedDates)) {
        console.log("-- BBBB")
        this.datesTmp = this.storedDates;
      }
    },
    getDateStr(date) {
      if (this.format === "month") {
        return getMonthDateStr(date);
      }

      return getDateStr(date);
    },
  },
  computed: {
    dateRangeText() {
      if (this.datesTmp.length === 0) {
        return "Pas de limite";
      }
      return this.datesTmp.join(" => ");
    },
    useStore() {
      return this.store !== undefined;
    },
    storedDates() {
      return this.useStore
        ? this.$store.getters[`${this.store}/${this.storeGetter}`]
        : this.initialValue;
    },
    dateMin() {
      if (this.min !== "") {
        return this.min;
      }
      if (this.periodType === "future-only") {
        return this.getDateStr(TOMORROW);
      }
      return "";
    },
    dateMax() {
      if (this.max !== "") {
        return this.max;
      }
      if (this.periodType === "past-only") {
        return this.getDateStr(YESTERDAY);
      }
      return "";
    },
  },
  watch: {
    storedDates() {
      // ici, comme j'ai un souci quand je set dates à [], ça me remet l'initialValue alors que je ne veux pas
      //  this.initRangeDates();     
    },
    initialValue:{
      handler(newValue, oldValue) {
        if (newValue !== oldValue){
          this.dates = this.initialValue;
          this.datesTmp = this.initialValue;
        }
      }
    }
  },
};
</script>
<!-- <style scoped>
.range-dates-picker-group button {
  margin-left: -2px;
}
</style> -->
<style>
.range-dates-picker-group .v-input__slot fieldset {
  height: calc(100% + 3px) !important;
}
.range-dates-picker-group.with-fast-btns fieldset {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
</style>
