<template>
  <div class="gdrt-container">
    <div class="d-flex flex-row mb-1">
      <p class="text-2xl mb-1">Google Discover Feed</p>
      <v-spacer></v-spacer>
      <v-select
        outline
        dense
        v-model="accountId"
        :items="accounts"
        label="Compte Google"
        class="select-account mx-2"
      ></v-select>

      <v-select
        outline
        dense
        v-model="language"
        :items="languages"
        label="Language"
        @change="setLanguage"
        class="select-language mx-2"
      ></v-select>
    </div>

    <v-expansion-panels
      multiple
      v-model="accountStatusPanelVisible"
      class="mb-2"
    >
      <v-expansion-panel v-show="devMode">
        <v-expansion-panel-header> MODE DEV </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-switch
            v-model="checkCronStatusOn"
            label="Check cronjobs status every 5s"
            hide-details
            inset
          ></v-switch>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels
      multiple
      v-model="accountStatusPanelVisible"
      class="mb-2"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          Horaires du crawler
          <v-spacer />
          <span v-if="smallerMinAgoItem">
            Le dernier il y a
            {{ smallerMinAgoItem.previous_schedule_minutes_ago }}min
          </span>
          <span v-else></span>
          <v-spacer />
          <span v-show="hasCurrentCrawl"> En cours </span>
          <v-progress-linear
            v-show="hasCurrentCrawl"
            indeterminate
            class="progress-current-crawl"
          ></v-progress-linear>
          <v-spacer />
          <v-btn
            :disabled="hasCurrentCrawl || startCrawlBtnJustClicked"
            class="mx-4 my-0"
            color="primary"
            small
            @click="startCrawl"
          >
            Lancer le crawl maintenant
          </v-btn>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Compte</th>
                  <th class="text-left">Précédent passage</th>
                  <th class="text-left">Actuellement en cours</th>
                  <th class="text-left">Prochain passage</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="a in accountStatusList" :key="a.google_account_id">
                  <td>
                    {{ a.google_account_name }}
                    ({{ a.google_account_email }})
                  </td>
                  <td>
                    <span v-show="a.previous_schedule_id">
                      <span class="mr-2">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              {{ a.previous_schedule_time_str }}
                            </span>
                          </template>
                          <span>[id:{{ a.previous_schedule_id }}]</span>
                        </v-tooltip>
                      </span>
                      <span class="mr-2">
                        (Il y a {{ a.previous_schedule_minutes_ago }}min)
                      </span>
                      <span
                        v-show="a.previous_schedule_minutes_ago < 6"
                        class="mr-2"
                      >
                        <v-chip color="info">recent</v-chip>
                      </span>
                      <span v-show="a.previous_schedule_status == 9">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-chip color="error">error</v-chip>
                            </span>
                          </template>
                          <span>{{ a.previous_schedule_error }}</span>
                        </v-tooltip>
                      </span>
                    </span>
                  </td>
                  <td>
                    <span v-show="a.current_schedule_time">
                      <v-progress-linear indeterminate></v-progress-linear>
                      <span>commencé à {{ a.current_schedule_time_str }}</span>
                    </span>
                  </td>
                  <td>
                    {{ a.next_schedule_time_str }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card elevation="3" outlined shaped tile class="mt-5 pa-0">
      <v-row class="split-card-2">
        <v-col cols="6">
          <!--v-card-title>Fréquence de mots</v-card-title-->
          <v-card-text class="pt-4">
            <discover-real-time-words-frequency-table :language="language" />
          </v-card-text>
        </v-col>
        <v-divider vertical class="my-3 mx-0 pa-0"></v-divider>
        <v-col cols="6">
          <!--v-card-title>Fréquence des sujets</v-card-title-->
          <v-card-text class="pt-4">
            <discover-real-time-topics-frequency-table
              :dates="dates"
              :language="language"
            />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <v-card outlined shaped tile class="mt-6 pa-0">
      <template>
        <v-card>
          <discover-real-time-urls-table
            :language="language"
            :accountId="accountId"
            :initialDates="dates"
            @periodChange="setDates"
            class="full-width"
          />
        </v-card>
      </template>
    </v-card>
  </div>
</template>
<script>
import axios from "@axios";
import { saveRmraView } from "@/utils/rmr-analytics";
import DiscoverRealTimeUrlsTable from "@/components/google-discover/gd-real-time/tables/DiscoverRealTimeUrlsTable.vue";
import DiscoverRealTimeWordsFrequencyTable from "@/components/google-discover/gd-real-time/tables/DiscoverRealTimeWordsFrequencyTable.vue";
import DiscoverRealTimeTopicsFrequencyTable from "@/components/google-discover/gd-real-time/tables/DiscoverRealTimeTopicsFrequencyTable.vue";
import { getDateStr, ONE_DAY_MICROTIME } from "@/utils/dates";
import { Konami, KonamiManager } from "@/utils/konami";

const TODAY = new Date();

export default {
  name: "DiscoverRealDashboard",
  components: {
    DiscoverRealTimeUrlsTable,
    DiscoverRealTimeWordsFrequencyTable,
    DiscoverRealTimeTopicsFrequencyTable,
  },
  data() {
    return {
      devMode: false,
      checkCronStatusOn: true,
      language: "fr",
      languages: ["fr", "en"],
      accounts: [{ text: "Tous", value: null }],
      accountId: null,
      accountStatusPanelVisible: [],
      accountStatusList: [],
      accountStatusInterval: null,
      dates: [],
      // datesInitialValue: [],
      hasCurrentCrawl: false,
      smallerMinAgoItem: null,
      startCrawlBtnJustClicked: false,
    };
  },
  mounted() {
    saveRmraView(this.$route); // commented whil TESTING
  },
  created() {
    //this.language = "fr";
    this.getAccounts();
    this.getScheduleAccountsStatus();
    this.initDatesInitialValue();
    if (this.checkCronStatusOn) {
      this.accountStatusInterval = setInterval(() => {
        if (this.checkCronStatusOn) {
          this.getScheduleAccountsStatus();
        }
      }, 5000); // 5 secondes
    }
    this.konamiManager = new KonamiManager(this, [
      new Konami([68, 69, 86], () => {
        this.devMode = !this.devMode;
      }),
    ]);
  },
  beforeDestroy() {
    clearInterval(this.accountStatusInterval);
  },
  methods: {
    initDatesInitialValue() {
      console.log("Dashboard initDatesInitialValue");
      const startDate = new Date(TODAY.getTime() - 30 * ONE_DAY_MICROTIME);
      this.dates = [this.getDateStr(startDate), this.getDateStr(TODAY)];
      console.log("initDatesInitialValue this.dates", this.dates);
    },
    setDates(dates) {
      console.log("dashboard setDates", dates);
      this.dates = dates;
    },
    getDateStr(date) {
      return getDateStr(date);
    },
    async startCrawl() {
      try {
        this.hasCurrentCrawl = true;
        this.startCrawlBtnJustClicked = true;
        setTimeout(() => {
          this.startCrawlBtnJustClicked = false;
        }, 10000);
        response = await axios.get("google-discover-real-time/start-crawl", {
          params: {},
        });
      } catch (error) {
        console.error("google-discover-real-time/start-crawl failed", error);
        return;
      }
    },
    async getScheduleAccountsStatus() {
      let response;
      try {
        response = await axios.get(
          "google-discover-real-time/schedule-accounts-status",
          {
            params: {},
          }
        );
      } catch (error) {
        clearInterval(this.accountStatusInterval);
        return;
      }
      /* {
        "google_account_id": 1,
        "google_account_name": "RMR Discover - Divertissement",
        "google_account_email": "rmr.gd.divertissement@gmail.com",
        "google_account_date_created": "2024-07-23T09:30:00",
        "google_account_date_deactivated": null,
        "previous_schedule_id": null,
        "previous_schedule_time": null,
        "previous_schedule_minutes_ago": 12,
        "previous_schedule_status": null,
        "previous_schedule_error": null,
        "current_schedule_id": null,
        "current_schedule_time": null,
        "next_schedule_id": null,
        "next_schedule_time": null
      } */

      let currentCrawl = false;
      let smallerMinAgoItem = null;

      const listItems = response.data.items.map((item) => {
        if (item.previous_schedule_time) {
          item.previous_schedule_time_str = item.previous_schedule_time
            .split("T")[1]
            .replace(/([0-9]{2}):([0-9]{2}):[0-9]{2}$/, "$1h$2");
          item.previous_schedule_time = new Date(item.previous_schedule_time);
          if (
            !smallerMinAgoItem ||
            item.previous_schedule_minutes_ago <
              smallerMinAgoItem.previous_schedule_minutes_ago
          ) {
            smallerMinAgoItem = item;
          }
        } else {
          item.previous_schedule_time_str = "";
          item.previous_schedule_time = null;
        }
        if (item.current_schedule_time) {
          item.current_schedule_time_str = item.current_schedule_time
            .split("T")[1]
            .replace(/([0-9]{2}):([0-9]{2}):[0-9]{2}$/, "$1h$2");
          item.current_schedule_time = new Date(item.current_schedule_time);
          currentCrawl = true;
        } else {
          item.current_schedule_time_str = "";
          item.current_schedule_time = null;
        }
        if (item.next_schedule_time) {
          item.next_schedule_time_str = item.next_schedule_time
            .split("T")[1]
            .replace(/([0-9]{2}):([0-9]{2}):[0-9]{2}$/, "$1h$2");
          item.next_schedule_time = new Date(item.next_schedule_time);
        } else {
          item.next_schedule_time_str = "";
          item.next_schedule_time = null;
        }
        return item;
      });
      this.hasCurrentCrawl = currentCrawl;
      this.smallerMinAgoItem = smallerMinAgoItem;
      this.accountStatusList = listItems;
      //console.log("this.accountStatusList", this.accountStatusList);
    },
    async getAccounts() {
      this.loading = true;
      const response = await axios.get("google-discover-real-time/accounts", {
        params: {},
      });
      console.log(response);
      let items = response.data.items.map((item) => ({
        value: item.id,
        text: item.email,
      }));
      items.unshift({
        value: null,
        text: "Tous",
      });
      this.accounts = items;
    },
    setLanguage: () => {
      console.log("change language");
    },
  },
  // computed: {},
  // watch: {},
};
</script>
<style>
.app-content-container.mx-auto.h-full.app-content-container-boxed {
  max-width: 100%;
}
</style>
<style lang="scss" scoped>
.progress-current-crawl {
  width: 20%;
}
.full-width {
  width: 100%;
}
.select-account {
  max-width: 300px;
}
.select-language {
  max-width: 100px;
}
.theme--light.v-tabs-items {
  background-color: #f4f5fa;
}
/* remove links underline */
.gdrt-container ::v-deep a {
  text-decoration: none !important;
}
.gdrt-container ::v-deep tbody tr:nth-of-type(even) {
  background-color: rgba(0, 1, 100, 0.08);
}
.gdrt-container ::v-deep tbody tr:hover {
  background-color: #10396f !important;
  td {
    color: white !important;
    a {
      color: white !important;
    }
  }
}
.split-card-2 .col.col-6 {
  flex: 0 0 49%;
}
</style>
